function AppFooterCopyright() {
	return (
		<div className="font-general-regular flex flex-col justify-center items-center text-center">
			<div className="text-lg text-ternary-dark dark:text-ternary-light flex flex-col justify-center items-center text-center">
				{/* &copy; {new Date().getFullYear()} */}
				<a
					href=""
					target="__blank"
					className="hover:cursor hover:text-indigo-600 dark:hover:text-indigo-300  duration-500"
				>
					&copy; {new Date().getFullYear()} SpaceRetail.ru
				</a>
				{/* <a
					href="mailto:manager@spaceretail.ru"
					target="__blank"
					className="hover:cursor hover:text-indigo-600 dark:hover:text-indigo-300 pt-2 duration-500"
				>
					manager@spaceretail.ru
				</a> */}
			</div>
		</div>
	);
}

export default AppFooterCopyright;
