import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import { motion } from "framer-motion";

const AppServices = ({ data }) => {
  const [activeTheme, setTheme] = useThemeSwitcher();
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
      className="sm:justify-between items-center sm:flex-row mt-12 md:mt-2"
    >
      <section className="py-5 sm:py-20 mt-20 sm:mt-24 border-t-2 border-primary-light dark:border-secondary-dark">
        <div className="text-center">
          <p className="font-roboto-400 text-2xl sm:text-4xl mb-1 text-ternary-dark dark:text-ternary-light">
            Создаем:
          </p>
          <div>
            <br />
            {/* <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">интернет-магазин</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">сайт-визитка</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">разработка брендбука</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">дизайн сайта</span>
  <br /><br />
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">3D анимация</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">Boostrap</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">.NET</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">SQL</span>
  <br /><br />
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">Angular</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">Miro</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">Tailwind CSS</span>
  <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center pl-5 pr-5 p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group animate-text bg-gradient-to-r from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">SCSS</span> */}
            <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center xs:m-1 sm:m-2 p-0.5 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-r animate-text from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">
              <span class="w-full relative px-2 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md">
                интернет-магазин
              </span>
            </span>
            <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center xs:m-1 sm:m-2 p-0.5 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-r animate-text from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">
              <span class="w-full relative px-2 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md">
                сайт-визитка
              </span>
            </span>
            <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center xs:m-1 sm:m-2 p-0.5 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-r animate-text from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">
              <span class="w-full relative px-2 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md">
                разработка брендбука
              </span>
            </span>
            <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center xs:m-1 sm:m-2 p-0.5 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-r animate-text from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">
              <span class="w-full relative px-2 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md">
                дизайн сайта
              </span>
            </span>
            <span class="font-roboto-400 flex-auto relative inline-flex items-center justify-center xs:m-1 sm:m-2 p-0.5 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-r animate-text from-orange-500 via-purple-500 via-pink-500 to-orange-500 dark:text-white">
              <span class="w-full relative px-2 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md">
                3D анимация
              </span>
            </span>
            <br />
            <br />
          </div>
        </div>
      </section>
    </motion.section>
  );
};

export default AppServices;
