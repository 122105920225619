import {
  FiGithub,
  FiTwitter,
  FiLinkedin,
  FiGlobe,
  FiVideoOff,
  FiPhoneCall,
} from "react-icons/fi";
import { Icon24Cancel, Icon56LogoVkColor } from "@vkontakte/icons";
import AppFooterCopyright from "./AppFooterCopyright";

const socialLinks = [
  {
    id: 1,
    icon: <Icon56LogoVkColor />,
    url: "https://vk.com/spaceretailru",
  },
  /*{
		id: 2,
		icon: <FiGithub />,
		url: 'https://github.com/',
	},*/
];

const AppFooter = () => {
  return (
    <div className="container mx-auto ">
      <div className="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark ">
        {/* Footer social links */}
        <div className="font-roboto-400 flex flex-col justify-center items-center mb-12 sm:mb-20">
          {/* <p className="text-3xl sm:text-4xl text-primary-dark dark:text-primary-light mb-5">
						жми сюда
					</p> */}
					
          <ul className="flex gap-4 sm:gap-8">
            {socialLinks.map((link) => (
              <a
                href={link.url}
                target="__blank"
                key={link.id}
                className="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg dark:bg-ternary-dark shadow-sm p-4 duration-300"
              >
                <i className="text-xl sm:text-2xl md:text-3xl">{link.icon}</i>
              </a>
            ))}
          </ul>
		  {/* <a
					href="mailto:manager@spaceretail.ru"
					target="__blank"
					className="font-general-regular text-xl hover:cursor hover:text-indigo-600 dark:hover:text-indigo-300 pt-4 duration-500 text-primary-dark dark:text-primary-light mb-5"
				>
					manager@spaceretail.ru
				</a> */}
        </div>

        <AppFooterCopyright />
      </div>
    </div>
  );
};

export default AppFooter;
