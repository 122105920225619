import './css/main.css';
import './css/tailwind.css';
import './css/App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

reportWebVitals();
